<template>
  <div
    class="
            absolute
            bg-black bg-opacity-50
            h-full
            w-full
            top-0
            left-0
            flex
            justify-center
            items-center
        "
    @click="$emit('close')"
  >
    <div class="bg-black h-5/6 xl:w-1/3 w-full overflow-y-auto" @click.stop>
      <div
        id="contactHeader"
        class="
                    bg-black-light
                    w-full
                    h-14
                    border-b border-grey
                    flex
                    justify-between
                    items-center
                    p-3
                    fixed
                    xl:static
                "
        style="z-index:1000"
      >
        <p class="text-white-dark cursor-pointer" @click="$emit('close')">
          {{ $t("cancel") }}
        </p>
        <p class="text-white-dark">{{ $t("sendToGym") }}</p>
        <p
          :class="state.canSend ? 'text-primary cursor-pointer' : 'text-grey'"
          @click="state.canSend ? (sendRequest()) : ''"
        >
          {{ $t("send") }}
        </p>
      </div>
      <div class="mt-16 xl:mt-0 pb-5">
        <div v-for="contactInput in state.contact" :key="contactInput">
          <p class="ml-5 mr-5 text-white">
            {{ $t(contactInput.label) }}
            <a class="text-primary">{{ contactInput.required ? "*" : "" }}</a>
          </p>
          <input-text
            :id="contactInput.label"
            class="ml-5 mr-5"
            v-model="contactInput.value"
            :borderClass="'border-none'"
            :inputClass="'text-white-dark bg-black-light pl-3'"
            @input="checkCanSend"
          ></input-text>
        </div>
        <div>
          <p class="ml-5 mr-5 text-white">
            {{ $t("dataProtection") }}
            <a class="text-primary">*</a>
          </p>
          <div class="flex ml-5 mt-2">
            <check-box
              :id="'privacyPolicy'"
              :activeColor="'primary'"
              :modelValue="state.checkedPrivacy"
              :defaultColor="'black'"
              @change="checkPrivacy"
            ></check-box>
            <p class="text-white-dark">
              {{ $t("dataText1") }}
              <a
                href="https://gym80.de/de/datenschutzerklaerung/"
                target="_blank"
                class="text-primary cursor-pointer"
                >{{ $t("privacyPolicy") }}</a
              >
              {{ $t("dataText2") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state.showError" class="absolute w-full h-full top-0 lef-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div class="w-80 h-auto p-5 bg-black-light rounded-md">
            <div class="flex justify-center">
                <p class="text-white-dark font-semibold text-lg">{{$t('error')}}</p>
            </div>
            <div class="flex flex-col justify-center mt-3 items-center">
                <p class="text-white-dark text-center">{{$t('send_error_message')}}</p>
                
                <div
                    class="
                    mt-3
                        h-12
                        w-48
                        bg-primary
                        rounded-lg
                        flex
                        justify-center
                        pt-2.5
                        cursor-pointer
                        hover:bg-black-light
                        transition
                        border-primary border-2
                    "
                    @click="state.showModal = true"
                >
                    <p class="text-white font-semibold">
                        {{ $t("close") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "../../../components/input/InputText.vue";
import CheckBox from "../../../components/input/InputCheckbox.vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, PRODUCTS_TYPES, STORE_TYPES } from "../../../store/types";
// import { useStore } from "vuex";

export default defineComponent({
  components: { InputText, CheckBox },
  emits: ["close"],
  setup(_1,ctx) {
    // const store = useStore();
    /**
     *  State
     */
    const store = useStore();
    const state = reactive({
        showError:false,
      contact: [
        {
          value: "",
          required: false,
          type: "text",
          label: "firstName",
        },
        { value: "", required: true, type: "text", label: "lastName" },
        { value: "", required: true, type: "text", label: "email" },
        { value: "", required: true, type: "text", label: "phone" },
        { value: "", required: true, type: "text", label: "street" },
        {
          value: "",
          required: true,
          type: "text",
          label: "houseNumber",
        },
        { value: "", required: true, type: "text", label: "postcode" },
        { value: "", required: true, type: "text", label: "location" },
      ],
      checkedPrivacy: false,
      canSend: false,
    });

    const i18n = useI18n();

    function checkCanSend() {
      let can = true;
      state.contact.forEach((contact) => {
        if (contact.required && contact.value == "") {
          can = false;
        }
      });
      if (!state.checkedPrivacy) {
        can = false;
      }
      state.canSend = can;
    }

    function checkPrivacy(event) {
      state.checkedPrivacy = event;
      checkCanSend();
    }

    async function sendRequest() {
      let bookmarkedMachines = JSON.parse(localStorage.getItem("bookmarks"));
      let form = {
        bookmarks: bookmarkedMachines,
        first_name: state.contact[0].value,
        last_name: state.contact[1].value,
        email: state.contact[2].value,
        phone_number: state.contact[3].value,
        street: state.contact[4].value,
        house_number: state.contact[5].value,
        postcode: state.contact[6].value,
        city: state.contact[7].value,
      };
      const response = await store.dispatch(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SEND_BOOKMARKS}`,
        form
      );
      if (response.status == 200) {
        localStorage.setItem("bookmarks", JSON.stringify([]));
        store.commit(
          `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.SET_BOOKMARKS_COUNT}`
        );
        ctx.emit('close');
      } else {
          state.showError = true;
      }
    }

    /**
     *  Functions
     */
    return {
      state,
      i18n,
      checkCanSend,
      checkPrivacy,
      sendRequest,
    };
  },
});
</script>

<style scoped>
#enterContact {
  width: 864px;
  height: 832px;
}
</style>
