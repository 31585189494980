<template>
    <div
        class="
            h-full
            w-full
            bg-black bg-opacity-50
            flex
            justify-center
            items-center
        "
        style="z-index: 1000"
        @click="$emit('close')"
    >
        <div class="lg:flex modalBox" @click.stop 
        style="z-index: 1000">
            <div class="bg-black cropperBox">
                <img
                    v-if="!state.cropped"
                    id="cropImageID"
                    class="block max-w-full"
                    :class="state.cropped ? 'hidden' : ''"
                    :src="image"
                    alt=""
                />
                <img
                    v-else
                    id="moveImageID"
                    class="block max-w-full"
                    :src="state.croppedImage"
                    alt=""
                />
            </div>
            <div
                class="flex justify-center h-64 lg:h-full bg-black-light rounded-r-lg infoBox"
            >
                <div class="flex flex-col items-center justify-between pb-5">
                    <div class="mt-5">
                        <p v-if="!state.cropped" class="text-white text-center text-lg font-bold">
                            {{ $t("edit_image") }}
                        </p>
                        <p v-else class="text-white text-center text-lg font-bold">
                            {{ $t("save_image") }}
                        </p>
                        <p v-if="!state.cropped" class="text-white p-3 text-center text-sm">
                            {{ $t("addImageInfoText") }}
                        </p>
                        <p v-else class="text-white p-3 text-center text-sm">
                            {{ $t("saveImageInfoText") }}
                        </p>
                    </div>
                    <div class="flex lg:flex-col items-center justify-center"><div v-if="!state.cropped" class="flex">
                            <div
                                class="
                                    w-10
                                    h-10
                                    bg-primary
                                    border-2 border-primary
                                    rounded-md
                                    ml-3
                                    lg:mt-5
                                    flex
                                    items-center
                                    justify-center
                                    cursor-pointer
                                    hover:scale-105
                                    transform
                                    transition
                                "
                                @click="zoomIn()"
                            >
                               <div class="flex">
                                   <SearchIcon :fillClass="'fill-white'" class="mt-1"/>
                                <p class="text-white-dark mb-1">+</p>
                                </div>
                            </div>
                            <div
                                class="
                                    w-10
                                    h-10
                                    bg-primary
                                    border-2 border-primary
                                    rounded-md
                                    ml-3
                                    lg:mt-5
                                    flex
                                    items-center
                                    justify-center
                                    cursor-pointer
                                    hover:scale-105
                                    transform
                                    transition
                                "
                                @click="zoomOut()"
                            >
                                <div class="flex">
                                   <SearchIcon :fillClass="'fill-white'" class="mt-1"/>
                                <p class="text-white-dark mb-1">-</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="!state.cropped" class="flex">
                            <div
                                class="
                                    w-10
                                    h-10
                                    bg-primary
                                    border-2 border-primary
                                    rounded-md
                                    ml-3
                                    lg:mt-5
                                    flex
                                    items-center
                                    justify-center
                                    cursor-pointer
                                    hover:scale-105
                                    transform
                                    transition
                                "
                                @click="rotateLeft()"
                            >
                                <RotateIcon
                                    :fillClass="'fill-white'"
                                    class="h-5 transform"
                                    style="transform: scaleX(-1)"
                                />
                            </div>
                            <div
                                class="
                                    w-10
                                    h-10
                                    bg-primary
                                    border-2 border-primary
                                    rounded-md
                                    ml-3
                                    lg:mt-5
                                    flex
                                    items-center
                                    justify-center
                                    cursor-pointer
                                    hover:scale-105
                                    transform
                                    transition
                                "
                                @click="rotateRight()"
                            >
                                <RotateIcon
                                    :fillClass="'fill-white'"
                                    class="h-5"
                                />
                            </div>
                        </div>
                        <div
                            v-if="!state.cropped"
                            class="
                                w-40
                                h-10
                                bg-primary
                                border-2 border-primary
                                rounded-md
                                ml-3
                                lg:mt-5
                                flex
                                justify-center
                                pt-2
                                cursor-pointer
                                hover:scale-105
                                transform
                                transition
                            "
                            @click="cropImage()"
                        >
                            <p class="text-white">
                                {{ $t("crop_image") }}
                            </p>
                        </div>
                        <div
                        v-else
                            class="
                                w-40
                                h-10
                                bg-primary
                                border-2 border-primary
                                rounded-md
                                ml-3
                                lg:mt-5
                                flex
                                justify-center
                                pt-2
                                cursor-pointer
                                hover:scale-105
                                transform
                                transition
                            "
                            @click="saveImage()"
                        >
                            <p class="text-white">
                                {{ $t("save_image") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, reactive } from "vue";
import Cropper from "cropperjs";

// import { useStore } from "vuex";
// import { HELPERS_TYPES, STORE_TYPES, VIEWER_TYPES } from "../../store/types";

export default defineComponent({
    components: {},
    emits: ["close", "saveImage"],
    props: ["image"],
    setup(_props, { emit }) {
        // const store = useStore();

        const state = reactive({
            cropped: false,
            croppedImage: "",
            imageURL:"",
            imageURLCroppe: "",
            blob: null,
        });
        let cropper = null;
        let mover = null

        setTimeout(() => {
            const cropImage = document.getElementById("cropImageID");
            cropper = new Cropper(cropImage, {
                aspectRatio: 1,
                minCropBoxWidth: 250,
                minCropBoxHeight: 250,
                viewMode:0,
                dragMode: "move",
                scalable: true,
                zoomable: true,
                rotatable: true,
                cropBoxMovable: true,
                cropBoxResizable: false,
            });
        }, 500);
        /**
         *  State
         */

        /**
         *  Functions
         */

        function cropImage() {
            state.imageURL = cropper
                .getCroppedCanvas({
                    width: 500,
                    height: 500,
                    imageSmoothingQuality: "high",
                })
                .toDataURL("image/png");
            state.cropped = true;
            state.croppedImage = state.imageURL;
            cropper.destroy();
            setTimeout(() => {
                const moveImageID = document.getElementById("moveImageID");
                mover = new Cropper(moveImageID, {
                    aspectRatio: 0,
                    minCropBoxWidth: 500,
                    minCropBoxHeight: 500,
                    viewMode: 0,
                    dragMode: "none",
                    scalable: true,
                    zoomable: false,
                    rotatable: false,
                    cropBoxMovable: false,
                    cropBoxResizable: false,
                });
            }, 500);
        }
        function saveImage() {
            mover.scale(0.30,0.30);
            state.imageURLCropped = mover
                .getCroppedCanvas({
                    width: 500,
                    height: 500,
                    imageSmoothingQuality: "high",
                })
                .toDataURL("image/png");
                
            emit("saveImage", {imageURL:state.imageURL, croppedImage: state.imageURLCropped});
        }

        

        function rotateLeft() {
            cropper.rotate(-10);
        }
        function rotateRight() {
            cropper.rotate(10);
        }
        function zoomIn() {
            cropper.zoom(0.2);
        }
        function zoomOut() {
            cropper.zoom(-0.2);
        }

        onMounted(() => {});
        onUnmounted(() => {});
        return {
            state,
            saveImage,
            rotateLeft,
            rotateRight,
            cropImage,
            zoomIn,
            zoomOut,
        };
    },
});
</script>

<style scoped>
.modalBox{
    height: 500px;
    width: 700px;
}
.cropperBox{
    height: 500px;
    width: 500px;
}
.infoBox{
    width: 200px;
}
@media (max-width: 1024px) {
.cropperBox{
    height: 300px;
    width: 100%;
}
.infoBox{
    width:100%;
}
}
</style>