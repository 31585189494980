<template>
  <main class="overscroll-none overflow-hidden">
    <div
      id="main_content"
      class="
                h-full
                lg:flex
                overflow-hidden
            "
    >
      <transition name="fade">
        <div
          v-if="!state.showEditor"
          class="
                        absolute
                        bg-black
                        h-full
                        flex flex-col
                        justify-center
                        items-center
                        pb-28
                        loading_spinner_canvas
                        w-full
                    "
          style="z-index:10000000;"
        >
          <loading-spinner />
          <p class="text-white font-semibold text-xl ml-5">
            {{ $t("configurator_loading") }}
          </p>
        </div>
      </transition>
      <div
        id="configurator"
        class="configurator border-b border-primary lg:border-none relative"
      >
        <canvas
          style="width:100%; height:100%; outline: none"
          id="scene"
          ref="scene"
        ></canvas>
        <div
          class="h-10 w-10 bg-white rounded-full absolute top-7 right-5 opacity-70 flex justify-center items-center"
          style="z-index:100"
        >
          <MovableIcon :fillClass="'fill-black'" />
        </div>
        <div
          class="h-10 w-10 bg-transparent rounded-full absolute top-7 left-5 opacity-70 flex justify-center items-center cursor-pointer border border-transparent transition-all hover:border-primary hover:bg-white-dark bg-opacity-30"
          style="z-index:100"
          @click="$router.push({ name: 'ProductSelection' })"
        >
          <ArrowIcon
            :fillClass="'fill-primary'"
            class="transform -rotate-90 scale-125 -ml-1"
          />
        </div>
      </div>
      <div
        id="side_bar"
        class="flex justify-center items-center side_bar w-full mt-5"
          style="z-index:1000000"
      >
        <product-editor
          v-if="state.showEditor"
          :loadedScene="state.scene"
        ></product-editor>
        <div v-else class="w-full justify-center flex items-center flex-col">
          <loading-spinner />
          <p class="text-white font-semibold text-xl ml-5">
            {{ $t("editor_loading") }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import { useStore } from "vuex";
import {
  HELPERS_TYPES,
  PRODUCTS_TYPES,
  STORE_TYPES,
  VIEWER_TYPES,
} from "../../store/types";
import ProductEditor from "./components/editor.vue";
import LoadingSpinner from "../../components/layout/LoadingSpinner.vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: { ProductEditor, LoadingSpinner },
  setup() {
    const store = useStore();
    /**
     *  State
     */
    const state = reactive({
      filters: {
        text: "",
        scene: null,
        showEditor: false,
      },
      engine: null,
    });
    const router = useRouter();
    const route = useRoute();
    const GET_PRODUCT = computed(() => {
      return store.getters[
        `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.GET_CHOSEN_PRODUCT}`
      ];
    });

    /**
     *  Functions
     */

    async function loadScene() {
      const scene = await store.dispatch(
        `${STORE_TYPES.VIEWER}/${VIEWER_TYPES.LOAD_SCENE}`
      );
      state.scene = scene;
      setTimeout(() => {
        state.showEditor = true;
      }, 2000);
    }
    function SET_FILTER_ACTIVE_ROUTE() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE}`
      );
    }
    onMounted(async () => {
      // console.log(route.params);
    //   let szeneContainer = document.getElementById("configurator")
    //   let szene = document.getElementById("scene")
    //   szene.style.width =  (szeneContainer.clientWidth + 'px')
    //   szene.style.height =  (szeneContainer.clientHeight + 'px')
    //   console.log(szene.style);
    //   console.log(szeneContainer.clientHeight);
      if (!route.params.bookmarks) {
        let product = await store.dispatch(
          `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.FETCH_SINGLE_PRODUCT}`,
          route.params.ID
        );
        await store.commit(
          `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.SET_CHOSEN_PRODUCT}`,
          product
        );
      }
      // console.log(GET_PRODUCT.value);
      if (GET_PRODUCT.value != null) {
       state.engine = await loadScene();
      } else {
        router.push("/");
      }
    });
    onUnmounted(() => {});
    return {
      state,
      SET_FILTER_ACTIVE_ROUTE,
    };
  },
});
</script>
<style scoped>
#configurator {
  background-color: white;
  height: 100%;
  width: calc(100vw - 373px);
}

#side_bar {
  width: 373px;
}
#loading_spinner_canvas {
  height: 100%;
  width: calc(100vw - 373px);
  z-index: 100000;
}

@media (max-width: 1024px) {
  #configurator {
    background-color: white;
    height: 40vh;
    width: 100%;
  }
  #side_bar {
    width: 100%;
    height: calc(60vh - 120px);
  }
  #loading_spinner_canvas {
    width: 100%;
    height: 40vh;
  }
}
</style>
