<template>
  <aside class="flex flex-col h-full p-3 overflow-y-scroll pb-24 w-full">
    <div
      id="editorHeader"
      class="w-full border-b border-grey border-opacity-50 pb-6"
    >
      <div class="flex mt-2 p-2 text-white text-lg font-bold">
        {{
          i18n.locale.value == "en"
            ? product.translations[0].translation
            : product.name
        }}
      </div>
      <div id="paymentInfo" class="flex justify-center pl-2">
        <p class="text-grey text-sm">{{ $t("paymentInfo") }}</p>
      </div>
      <!-- <div class="flex justify-center">
        <div
          class="
                        w-80
                        h-12
                        bg-transparent
                        border-2
                        rounded-md
                        ml-3
                        mt-7
                        flex
                        justify-center
                        pt-2
                        cursor-pointer
                        hover:scale-105
                        transform
                        transition
                    "
          :class="
            !state.saved
              ? state.saving
                ? 'border-orange'
                : 'border-primary'
              : 'border-green'
          "
          @click="!state.saved && !state.saving ? addToBookmarks() : ''"
        >
          <p
            :class="
              !state.saved
                ? state.saving
                  ? 'text-orange'
                  : 'text-primary'
                : 'text-green'
            "
          >
            {{
              !state.saved
                ? state.saving
                  ? $t("saving_bookmarks")
                  : product.bookmark_id != null
                  ? $t("save")
                  : $t("goToBookmarks")
                : $t("saved")
            }}
          </p>
        </div>
      </div> -->
    </div>
    <!-- <div
            id="productInfo"
            class="border-b border-grey border-opacity-50 pb-3"
        >
            <div class="flex justify-between p-5">
                <div class="w-20">
                    <p class="text-white font-semibold text-lg text-center">
                        {{ product.height
                        }}<a class="text-sm font-normal">mm</a>
                    </p>
                    <p class="text-grey text-sm text-center">
                        {{ $t("height") }}
                    </p>
                </div>
                <div class="w-20">
                    <p class="text-white font-semibold text-lg text-center">
                        {{ product.width }}<a class="text-sm font-normal">mm</a>
                    </p>
                    <p class="text-grey text-sm text-center">
                        {{ $t("width") }}
                    </p>
                </div>
                <div class="w-20">
                    <p class="text-white font-semibold text-lg text-center">
                        {{ product.length
                        }}<a class="text-sm font-normal">mm</a>
                    </p>
                    <p class="text-grey text-sm text-center">
                        {{ $t("length") }}
                    </p>
                </div>
            </div>
            <div class="flex justify-between p-5 pl-20 pr-20">
                <div class="w-20">
                    <p class="text-white font-semibold text-lg text-center">
                        {{ product.weight
                        }}<a class="text-sm font-normal">kg</a>
                    </p>
                    <p class="text-grey text-sm text-center">
                        {{ $t("totalWeight") }}
                    </p>
                </div>
                <div class="w-20">
                    <p class="text-white font-semibold text-lg text-center">
                        {{ product.standard_weight
                        }}<a class="text-sm font-normal">kg</a>
                    </p>
                    <p class="text-grey text-sm text-center">
                        {{ $t("normalWeightblock") }}
                    </p>
                </div>
            </div>
            <div class="flex"></div>
        </div> -->
    <div>
      <div v-if="upholsteryMaterials.length != 0" class="mt-3 p-2">
        <p class="text-white text-lg font-semibold">
          {{ $t("upholstery") }}
        </p>
        <p class="text-grey text-sm">{{ $t("upholsteryInfo") }}</p>
      </div>
      <div
        class="flex flex-col w-full"
        v-for="material of upholsteryMaterials"
        :key="material.id"
      >
        <div class="flex flex-wrap ml-1">
          <div
            v-for="color in state.availableUpholsteryColors"
            :key="color.show"
            :title="selectedLanguage == 'en' ? color.name_en : color.name_de"
            :style="'background-color: ' + color.show"
            class="
                            h-14
                            w-14
                            shadow-md
                            rounded-md
                            transform
                            transition-all
                            cursor-pointer
                            hover:shadow-lg
                            hover:border-grey
                            border
                            m-0.5
                        "
            :class="
              selectedColor(material.id) == color.name_de ||
              selectedColor(material.id) == color.name_en
                ? 'scale-105 border border-white'
                : ''
            "
            @click="
              setLackColor(color.value, material.id);
              saveColor(material.id, color);
            "
          ></div>
        </div>
        <p class="text-white ml-2">{{ selectedColor(material.id) }}</p>
      </div>
    </div>
    <div>
      <div class="mt-3 p-2">
        <p class="text-white text-lg font-semibold">
          {{ $t("frame") }}
        </p>
        <p class="text-grey text-sm">{{ $t("frameInfo") }}</p>
      </div>
      <div
        class="flex flex-col w-full"
        v-for="material of baseMaterials"
        :key="material.id"
      >
        <div class="text-white flex mt-3 p-2 font-semibold">
          {{ material.name == "Base-1" ? $t("frame") : $t("covering") }}
        </div>
        <div class="flex flex-wrap ml-1">
          <div
            v-for="color in state.availableBorderColors"
            :key="color.show"
            :title="selectedLanguage == 'en' ? color.name_en : color.name_de"
            :style="'background-color: ' + color.show"
            class="
                            h-14
                            w-14
                            shadow-md
                            rounded-md
                            transform
                            transition-all
                            cursor-pointer
                            hover:shadow-lg
                            hover:border-grey
                            border
                            m-0.5
                        "
            :class="
              selectedColor(material.id) == color.name_de ||
              selectedColor(material.id) == color.name_en
                ? 'scale-105 border border-white'
                : ''
            "
            @click="
              setLackColor(color.value, material.id);
              saveColor(material.id, color);
            "
          ></div>
        </div>
        <div class="flex flex-wrap ml-1">
          <div
            v-for="color in state.availableSpezialBorderColors"
            :key="color.show"
            :title="selectedLanguage == 'en' ? color.name_en : color.name_de"
            :style="'background-color: ' + color.show"
            class="
                            h-14
                            w-14
                            shadow-md
                            rounded-md
                            transform
                            transition-all
                            cursor-pointer
                            hover:shadow-lg
                            hover:border-grey
                            border
                            m-0.5
                            relative
                        "
            :class="
              selectedColor(material.id) == color.name_de ||
              selectedColor(material.id) == color.name_en
                ? 'scale-105 border border-white'
                : ''
            "
            @click="
              setLackColor(color.value, material.id);
              saveColor(material.id, color);
            "
          >
          <div class="absolute flex items-center justify-center pb-0.5 text-center text-xs bottom-1 w-full h-3 text-white-dark bg-black text-opacity-50 bg-opacity-10">{{$t("special")}}</div>
          </div>
        </div>
        <p class="text-white ml-2">{{ selectedColor(material.id) }}</p>
      </div>
      <div
        v-if="imageMaterial.length != null && upholsteryMaterials.length != 0"
        class="border-b border-grey border-opacity-50 pb-3"
      >
        <div class="mt-3 p-2">
          <p class="text-white text-lg font-semibold">
            {{ $t("embroidery") }}
          </p>
          <p class="text-grey text-sm">{{ $t("embroideryInfo") }}</p>
        </div>
        <div class="lg:block flex w-full">
          <div class="flex group m-2">
            <img
              id="previewUserImage"
              :class="state.uploaded ? 'w-16 ml-5' : 'hidden'"
              src=""
              alt=""
            />
            <div>
              <div
                v-if="state.uploaded"
                class="
                                    flex
                                    cursor-pointer
                                    items-center
                                    ml-2
                                    mb-2
                                "
                @click="editImage()"
              >
                <p class="text-white text-left">
                  {{ $t("edit_image") }}
                </p>

                <EditIcon :fillClass="'fill-primary'" class="mr-2 ml-2" />
              </div>
              <div
                v-if="state.uploaded"
                class="flex cursor-pointer items-center ml-2"
                @click="clearImage"
              >
                <p class="text-white text-left">
                  {{ $t("remove_image") }}
                </p>
                <CancelIcon :fillClass="'fill-primary'" class="mr-2 ml-2" />
              </div>
            </div>
          </div>
          <div
            v-if="!state.uploaded"
            class="
                            h-14
                            w-80
                            border border-opacity-50 border-dashed
                            ml-2
                            flex
                            justify-center
                            pt-4
                        "
            :class="state.dragging ? 'border-red' : 'border-grey'"
          >
            <p class="text-grey text-sm">
              {{ $t("addEmbroidery")
              }}<a
                class="text-primary cursor-pointer"
                @click="$refs.fileInput.click()"
                >{{ $t("search") }}</a
              >
            </p>
            <input
              type="file"
              accept="image/*"
              ref="fileInput"
              class="hidden"
              @change="encodeImageFileAsURL(null)"
            />
          </div>
        </div>
      </div>
      <div class="mt-10">
        <p class="text-white text-lg font-semibold ml-3">
          {{ $t("sendRequestInfo") }}
        </p>
        <a href="https://www.gym80.de/de/kontakt/" target="_blank">
          <div
            class="
                        w-80
                        h-12
                        bg-primary
                        border-2 border-primary
                        rounded-md
                        ml-3
                        mt-5
                        flex
                        justify-center
                        pt-2
                        cursor-pointer
                        hover:scale-105
                        transform
                        transition
                    "
            @click="addToBookmarks()"
          >
            <p class="text-white">{{ $t("sendRequest") }}</p>
          </div>
        </a>
      </div>
    </div>

    <enter-contact
      v-if="state.enterContact"
      @close="state.enterContact = false"
      class="pt-24"
    ></enter-contact>
    <div
      v-if="state.openCropImage"
      class="absolute top-4 left-0 h-screen w-full"
    >
      <crop-image
        @close="state.openCropImage = false"
        :image="state.tempUserImage"
        @saveImage="setImage"
      />
    </div>
    <div
      v-if="state.dragging"
      class="absolute bg-black bg-opacity-50 h-full w-full top-0 left-0 flex justify-center items-center"
      @click="state.dragging = false"
    >
      <div class="w-96 h-64 bg-black-light rounded-lg" @click.stop>
        <div class="flex justify-center mt-3">
          <p class="text-white-dark text-lg">Drop your Image here</p>
        </div>
        <div class="flex justify-center items-center mt-2">
          <div
            class="h-48 w-80 border border-grey border-dashed -5 flex justify-center items-center"
          >
            <DropIcon :fillClass="'fill-grey'" class="transform scale-150" />
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import { useStore } from "vuex";
import {
  // HELPERS_TYPES,
  PRODUCTS_TYPES,
  STORE_TYPES,
} from "../../../store/types";

import { BABYLON } from "vue-babylonjs";
import { useI18n } from "vue-i18n";
import CropImage from "../modals/cropImageModal.vue";
import EnterContact from "../../bookmarks/modals/enterContact.vue";

export default defineComponent({
  components: { CropImage, EnterContact },
  props: ["loadedScene"],
  setup(props) {
    const store = useStore();
    /**
     *  State
     */
    const state = reactive({
      saved: false,
      saving: false,
      colors: [],
      userImage: null,
      croppedImage: null,
      tempUserImage: null,
      imageType: null,
      uploaded: false,
      openCropImage: false,
      images: null,
      enterContact: false,
      dragging: false,
      availableUpholsteryColors: [
        {
          show: "#FFFFFF",
          value: "#FFFFFF",
          name_de: "Weiß",
          name_en: "White",
        },
        {
          show: "#C3AC75",
          value: "#C3AC75",
          name_de: "Beige",
          name_en: "Beige",
        },
        {
          show: "#71706C",
          value: "#71706C",
          name_de: "Schiefer",
          name_en: "Schist",
        },
        {
          show: "#BBBAB7",
          value: "#BBBAB7",
          name_de: "Silber",
          name_en: "Silver",
        },
        {
          show: "#974A1F",
          value: "#974A1F",
          name_de: "Lima",
          name_en: "Lima",
        },
        {
          show: "#873E31",
          value: "#873E31",
          name_de: "Terracotta",
          name_en: "Teracotta",
        },
        {
          show: "#A21926",
          value: "#A21926",
          name_de: "Feuer",
          name_en: "Fire",
        },
        {
          show: "#951F27",
          value: "#951F27",
          name_de: "Mohn",
          name_en: "Poppy",
        },
        {
          show: "#681628",
          value: "#681628",
          name_de: "Rubin",
          name_en: "Ruby",
        },
        {
          show: "#40392E",
          value: "#40392E",
          name_de: "Taupe",
          name_en: "Taupe",
        },
        {
          show: "#643B2F",
          value: "#643B2F",
          name_de: "Hellbraun",
          name_en: "Light Brown",
        },
        {
          show: "#2E211B",
          value: "#2E211B",
          name_de: "Dunkelbraun",
          name_en: "Dark Brown",
        },
        {
          show: "#000000",
          value: "#000000",
          name_de: "Schwarz",
          name_en: "Black",
        },
        {
          show: "#818C1F",
          value: "#818C1F",
          name_de: "Limone",
          name_en: "Lime",
        },
        {
          show: "#555847",
          value: "#555847",
          name_de: "Avocado",
          name_en: "Avocado",
        },
        {
          show: "#0E747E",
          value: "#0E747E",
          name_de: "Agave",
          name_en: "Agave",
        },
        {
          show: "#1E2A67",
          value: "#1E2A67",
          name_de: "Atoll",
          name_en: "Atoll",
        },
        {
          show: "#373536",
          value: "#373536",
          name_de: "Granit",
          name_en: "Granite",
        },
      ],
      availableBorderColors: [
        {
          show: "#FFFFFF",
          value: "#FFFFFF",
          name_de: "Weiß",
          name_en: "White",
        },
        {
          show: "#7A8387",
          value: "#6d6d6e",
          name_de: "Silber",
          name_en: "Silver",
        },
        {
          show: "#312F2D",
          value: "#242221",
          name_de: "Anthrazit",
          name_en: "Anthracite",
        },
        {
          show: "#000000",
          value: "#000000",
          name_de: "Schwarz",
          name_en: "Black",
        },
      ],
      availableSpezialBorderColors: [
        {
          show: "#b5a58a",
          value: "#96815c",
          name_de: "Champagner",
          name_en: "Champagne",
        },
        {
          show: "#7d765a",
          value: "#574c29",
          name_de: "Schilfgrün",
          name_en: "Reed green",
        },
        {
          show: "#A42821",
          value: "#730701",
          name_de: "Feuerrot",
          name_en: "Flame red",
        },
      ],
    });

    const scene = computed(() => {
      return props.loadedScene;
    });
    const materials = computed(() => {
      let materials = null;
      if (props.loadedScene != null) {
        materials = props.loadedScene.materials;
      }
      return materials;
    });
    const baseMaterials = computed(() => {
      if (materials.value == null) return [];
      else
        return materials.value
          .filter((material) => material.id.indexOf("Base") > -1)
          .sort();
    });

    const upholsteryMaterials = computed(() => {
      if (materials.value == null) return [];
      else
        return materials.value
          .filter((material) => material.id.indexOf("Upholstery") > -1)
          .sort();
    });
    const imageMaterial = computed(() => {
      if (materials.value == null) return [];
      else
        return materials.value
          .filter((material) => material.id.indexOf("customer_logo") > -1)
          .sort();
    });

    const i18n = computed(() => {
      const i18n = useI18n();
      return i18n;
    });

    const selectedLanguage = computed(() => {
      const i18n = useI18n();
      return i18n.locale.value;
    });

    const product = computed(
      () =>
        store.getters[
          `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.GET_CHOSEN_PRODUCT}`
        ]
    );
    /**
     *  Functions
     */

    function clearImage() {
      state.uploaded = false;
      var previewContainer = document.getElementById("previewUserImage");
      previewContainer.src = null;
      state.userImage = null;
      let material = scene.value.getMaterialByID("customer_logo");

      let texture = new BABYLON.Texture(null, scene.value, true, false);
      texture.hasAlpha = true;
      material.albedoTexture = texture;
    }

    function editImage() {
      // state.tempUserImage = state.userImage;
      state.openCropImage = true;
    }
    function setImage(event) {
      var previewContainer = document.getElementById("previewUserImage");
      let material = scene.value.getMaterialByID("customer_logo");
      var fd = new FormData();
      fd.append("userImage", dataURItoBlob(event.imageURL));
      fd.append("croppedImage", dataURItoBlob(event.croppedImage));
      state.images = fd;
      state.userImage = event.imageURL;
      state.croppedImage = event.croppedImage;
      previewContainer.src = event.imageURL;
      state.uploaded = true;

      state.openCropImage = false;
      let texture = new BABYLON.Texture(
        event.croppedImage,
        scene.value,
        true,
        false,
        1
      );
      texture.hasAlpha = true;
      material.albedoTexture = texture;
      // material.fillMode = 3;
      material.albedoColor = new BABYLON.Color3(1, 1, 1);
    }
    function setLackColor(color, materialId) {
      let material = scene.value.getMaterialByID(materialId);
      material.albedoColor = BABYLON.Color3.FromHexString(color);
      console.log(materialId);
      if (materialId == "Upholstery-1") {
        material.roughness = 1;
      }
    }
    function saveColor(materialId, selectedColor) {
      let found = false;
      state.colors.forEach((color) => {
        if (color.id == materialId) {
          color.value = selectedColor.value;
          color.name_de = selectedColor.name_de;
          color.name_en = selectedColor.name_en;
          found = true;
        }
      });
      if (found == false) {
        state.colors.push({
          id: materialId,
          value: selectedColor.value,
          name_de: selectedColor.name_de,
          name_en: selectedColor.name_en,
        });
      }
    }
    function selectedColor(materialId) {
      let showColor = "";
      if (state.colors.length != 0) {
        state.colors.forEach((color) => {
          if (color.id == materialId) {
            const i18n = useI18n();
            if (i18n.locale.value == "en") {
              showColor = color.name_en;
            } else {
              showColor = color.name_de;
            }
          }
        });
      }
      return showColor;
    }

    function dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }

    async function addToBookmarks() {
      // state.saving = true;
      // let response = {
      //   userImage: null,
      //   croppedImage: null,
      //   directory: null,
      // };
      // if (state.images != null) {
      //   response = await store.dispatch(
      //     `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SAVE_USER_IMAGES}`,
      //     state.images
      //   );
      // }
      // if (localStorage.getItem("bookmarks")) {
      //   let savedProducts = JSON.parse(localStorage.getItem("bookmarks"));
      //   if (savedProducts.length > 0) {
      //     if (product.value.bookmark_id == null) {
      //       let bookmark_id =
      //         savedProducts[savedProducts.length - 1].bookmark_id + 1;
      //       savedProducts.push({
      //         product_id: product.value.id,
      //         bookmark_id: bookmark_id,
      //         colors: state.colors,
      //         image: response.userImage,
      //         croppedImage: response.croppedImage,
      //         imageType: state.imageType,
      //         directory: response.directory,
      //       });
      //     } else {
      //       if (savedProducts.length > 0) {
      //         savedProducts.forEach((savedProduct) => {
      //           if (
      //             savedProduct.bookmark_id != null &&
      //             product.value.bookmark_id != null
      //           ) {
      //             if (savedProduct.bookmark_id == product.value.bookmark_id) {
      //               savedProduct.colors = state.colors;
      //               savedProduct.image = response.userImage;
      //               savedProduct.croppedImage = response.croppedImage;
      //               savedProduct.imageType = state.imageType;
      //               savedProduct.directory = response.directory;
      //             }
      //           }
      //         });
      //       }
      //     }
      //     localStorage.setItem("bookmarks", JSON.stringify(savedProducts));
      //   } else {
      //     let savedProducts = [
      //       {
      //         product_id: product.value.id,
      //         bookmark_id: 1,
      //         colors: state.colors,
      //         image: response.userImage,
      //         croppedImage: response.croppedImage,
      //         imageType: state.imageType,
      //         directory: response.directory,
      //       },
      //     ];
      //     localStorage.setItem("bookmarks", JSON.stringify(savedProducts));
      //   }
      // } else {
      //   let savedProducts = [
      //     {
      //       product_id: product.value.id,
      //       bookmark_id: 1,
      //       colors: state.colors,
      //       image: response.userImage,
      //       croppedImage: response.croppedImage,
      //       imageType: state.imageType,
      //       directory: response.directory,
      //     },
      //   ];
      //   localStorage.setItem("bookmarks", JSON.stringify(savedProducts));
      // }
      // state.saving = false;
      // state.saved = true;
      // store.commit(
      //   `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.SET_BOOKMARKS_COUNT}`
      // );
      // setTimeout(() => {
      //   state.saved = false;
      // }, 1500);
    }
    function encodeImageFileAsURL(dragFile) {
      state.uploaded = false;
      var file;
      if (dragFile) {
        file = dragFile[0];
      } else {
        file = this.$refs.fileInput.files[0];
      }
      console.log(file);
      state.imageType = file.type.split("/")[1];
      // var previewContainer = document.getElementById("previewUserImage");
      var reader = new FileReader();
      reader.onloadend = function() {
        state.tempUserImage = reader.result;
        // previewContainer.src = reader.result;
        // state.uploaded = true;
      };
      reader.readAsDataURL(file);
      state.openCropImage = true;
    }

    //Drag File events
    function dragenter(event) {
      event.preventDefault();
      console.log("enter");
      state.dragging = true;
    }
    async function drop(event) {
      event.preventDefault();
      let droppedFiles = event.dataTransfer.files;
      if (!droppedFiles) return;
      await encodeImageFileAsURL(droppedFiles);
      state.dragging = false;
    }
    function dragover(event) {
      event.preventDefault();
    }

    onMounted(() => {
      if (product.value.colors) {
        product.value.colors.forEach((color) => {
          saveColor(color.id, color);
        });
      }
      console.log(product.value);
      if (product.value.image != null) {
        var previewContainer = document.getElementById("previewUserImage");
        let image = product.value.image.substring(7);
        let imagePath = process.env.VUE_APP_MODEL_FILE + image;
        previewContainer.src = imagePath;
        state.userImage = product.value.image;
        state.uploaded = true;
        console.log("test");
      }
      window.addEventListener("dragenter", dragenter);
      window.addEventListener("dragover", dragover);
      window.addEventListener("drop", drop);
    });

    onUnmounted(() => {
      if (scene.value != null) {
        scene.value.dispose();
      }
    });
    return {
      state,
      scene,
      materials,
      selectedColor,
      addToBookmarks,
      saveColor,
      setLackColor,
      product,
      selectedLanguage,
      i18n,
      upholsteryMaterials,
      baseMaterials,
      encodeImageFileAsURL,
      clearImage,
      setImage,
      editImage,
      imageMaterial,
    };
  },
});
</script>

<style></style>
